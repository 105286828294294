<template>
  <div class="home">
    <!-- INTRO -->
    <section id="top_image">
      <div class="top-image-copy">
        <div class="top-image-copy-inner">
          <h1>Tom Burtless</h1>
          <h5>Web Developer</h5>
        </div>
      </div>
    </section>
    <!-- RESUME -->
    <h2>
      <div class="container">
        Experience
      </div>
    </h2>
    <section id="resume">
      <div class="container">
        <div class="jobs">
          <div class="job" v-for="job in jobs" :key="job.title">
            <div class="job-top">
              <h3>{{ job.name}}</h3>
              <p class="dates">{{ job.start }} <span v-if="job.end">- {{ job.end}}</span> <span v-else>- Present</span></p>
              <h4>{{job.title}}</h4>
              <ul class="details">
                <li v-for="detail in job.details" :key="detail">{{ detail }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- PORTFOLIO -->
    <h2>
      <div class="container">
        Portfolio
      </div>
    </h2>
    <section id="portfolio">
      <div class="container">
        <div class="portfolio-items">
          <div class="portfolio-item" v-for="(portfolio_item, index) in portfolio" :key="'portfolio_item_' + index" :class="{ 'active' : active == 'portfolio_item_' + index }">
            <div class="portfolio-image">
              <div class="portfolio-image-inner" @click="setActive('portfolio_item_' + index)">
                <picture>
                  <source :srcset="getImgUrl(portfolio_item.desktop_image + '.webp')" type="image/webp" media="(min-width: 768px)">
                  <source :srcset="getImgUrl(portfolio_item.desktop_image + '.jpg')" type="image/jpeg" media="(min-width: 768px)">
                  <source :srcset="getImgUrl(portfolio_item.mobile_image + '.webp')" type="image/webp">
                  <source :srcset="getImgUrl(portfolio_item.mobile_image + '.jpg')" type="image/jpeg">
                  <img :src="getImgUrl(portfolio_item.desktop_image + '.jpg')" :alt="portfolio_item.name + ' Desktop Mockup'">
                </picture>
                <div class="portfolio-details">
                  <div class="portfolio-inner">
                    <h3>{{ portfolio_item.name }}</h3>
                    <ul class="details">
                      <li v-for="detail in portfolio_item.details" :key="detail">{{ detail }}</li>
                    </ul>
                    <a :href="portfolio_item.url" target="_blank">View Website</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- SKILLS -->
    <h2>
      <div class="container">
        Skills
      </div>
    </h2>
    <section id="skills" class="container">
      <div class="skills-inner">
        <div class="skill">
          <h3>Languages</h3>
          <div class="skills">
            <span v-for="language in skills.languages" :key="language.name">{{language.name}} <i>{{language.time}}</i></span>
          </div>
        </div>
        <div class="skill">
          <h3>Libraries</h3>
          <div class="skills">
            <span v-for="library in skills.libraries" :key="library.name">{{library.name}} <i>{{library.time}}</i></span>
          </div>
        </div>
        <div class="skill">
          <h3>Software</h3>
          <div class="skills">
            <span v-for="single_software in skills.software" :key="single_software.name">{{single_software.name}} <i>{{single_software.time}}</i></span>
          </div>
        </div>
        <div class="skill">
          <h3>APIS</h3>
          <div class="skills">
            <span v-for="api in skills.apis" :key="api.name">{{api.name}} <i>{{api.time}}</i></span>
          </div>
        </div>
      </div>
    </section>
    <!-- CONTACT -->
    <h2>
      <div class="container">
        Contact Me
      </div>
    </h2>
    <section id="contact">
      <div class="contact-inner">
        <h3>Have a project in mind?<br>Looking to grab a coffee?</h3>
        <a :href="'mailto:' + contact" ckass="contact-button">Get in touch</a>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'home',
  components: {},
  data: function() {
    return {
      jobs: [
        {
          name: "American Technologies",
          title: "Senior Developer",
          start: "Feb 2021",
          end: null,
          details: [
            "Developing heavily customized and optimized B2B eCommerce platforms",
            "Connecting to multiple APIs including Magento, WooCommerce and Acumatica",
            "Custom Wordpress theme and plugin development",
            "Utilization of Laravel & ElasticSearch",
            "Custom API Development",
            "Code repositories managed with Github via git"
          ]
        },
        {
          name: "Mr. Smith Agency",
          title: "Digital Director",
          start: "Oct 2018",
          end: "Feb 2021",
          details: [
            "Working alongside a UI/UX Manager to compose beautiful & responsive websites",
            "Utilizing multiple platforms, languages and libraries - PHP, JS, SASS, MySQL, Liquid, VueJs, Laravel, Wordpress, Shopify, Bootstrap (3 and 4)",
            "Develop custom web applications - internally and for clients",
            "API development - both working with pre-existing APIs and developing our own",
            "Code repositories managed with GitLab"
          ]
        },
        {
          name: "Buffalo News",
          title: "Development Manager",
          start: "Jun 2017",
          end: "Oct 2018",
          details: [
            "Led a team of 6 developers working on the main Buffalo News website, mobile applications and additional web platforms",
            "Launched (and assisted in developing) many initiatives to increase digital subscriptions and enhance user experience on the Buffalo News website",
            "Helped in creating the initial BN Tech website and dashboard utilizing Express and VueJs",
            "Launched the BN Blitz program in fall of 2018 to help obtain an increase in digital subscribers"
          ]
        },
        {
          name: "PoolSupplies.com",
          title: "Lead Developer",
          start: "Mar 2014",
          end: "Jun 2017",
          details: [
            "Developed a custom shopping cart platform in CodeIgniter that connected to many APIs (Amazon, eBay, NetSuite, PayPal amongst others)",
            "Developed many internal tools for our sales and customer service departments",
            "Everything developed using a variety of languages and libraries - PHP, JS, Angular.js, MySQL, CSS etc"
          ]
        },
        {
          name: "Lactalis American Group",
          title: "Jr Web Developer",
          start: "Apr 2012",
          end: "Mar 2014",
          details: [
            "Built  promotional and marketing websites for various marketing sections of Lactalis",
            "Assisted Senior Developer with modifying and adjusting a custom CMS built off Cake PHP",
            "Languages utilized: PHP, MySQL, CSS3 (including a variety of custom media queries), HTML5(utilizing canvas to do custom animations)"
          ]
        }
      ],
      portfolio: [
        {
          name: "Toscana USA",
          url: "https://toscanausa.com/",
          desktop_image: "toscana-desktop",
          mobile_image: "toscana-mobile",
          details: [
            "Shopify eCommerce with completely custom theme development",
            "Assistance in implementing 3rd party shipping fulfillment",
            "Utilizing CSS3, HTML5, and as few third party libraries as possible"
          ]
        },
        {
          name: "Creamy Creation",
          url: "https://creamycreation.com/",
          desktop_image: "creamy-desktop",
          mobile_image: "creamy-mobile",
          details: [
            "Wordpress Website with ACF implementation",
            "Utilizing CSS3, HTML5, and as few third party libraries as possible",
            "Multi part form custom development"
          ]
        },
        {
          name: "Everyday Ink",
          url: "https://everydayinkprinting.com/",
          desktop_image: "everydayink-desktop",
          mobile_image: "everydayink-mobile",
          details: [
            "Wordpress Website with ACF implementation",
            "Utilizing CSS3, HTML5, and as few third party libraries as possible",
            "Splash page development"
          ]
        },
        {
          name: "Palm Beach Masters",
          url: "https://www.palmbeachmasters.com",
          desktop_image: "pbms-desktop",
          mobile_image: "pbms-mobile",
          details: [
            "Wordpress eCommerce Website with WooCommerce and Tickera integration",
            "Custom API integration for rider registration validation against 3rd party",
            "Custom Video Player GUI (using Vimeo videos) and Image Slider"
          ]
        },
        {
          name: "Feature Eatery",
          url: "http://featureeatery.com/",
          desktop_image: "feature-desktop",
          mobile_image: "feature-mobile",
          details: [
            "Laravel API headless backend, Vue.JS application front end",
            "Custom Admin panel created for editing menu items and about information",
            "Custom page created for in restaurant menu display"
          ]
        },
        {
          name: "Mr. Smith Agency",
          url: "https://mrsmith.agency/",
          desktop_image: "mrsmith-desktop",
          mobile_image: "mrsmith-mobile",
          details: [
            "Wordpress Website with ACF implementation",
            "A slew of custom animations throughout the website",
            "Custom cursor implementation",
            "Animated and customized CTAs depending on site location"
          ]
        },
        {
          name: "Aurora Machine",
          url: "https://auroramachine.com/",
          desktop_image: "aurora-desktop",
          mobile_image: "aurora-mobile",
          details: [
            "Wordpress Website with ACF implementation",
            "Vimeo background videos with SVG cutouts",
            "Custom animations"
          ]
        },
        {
          name: "Waxlight bar a' vin",
          url: "http://waxlightbaravin.com/",
          desktop_image: "waxlight-desktop",
          mobile_image: "waxlight-mobile",
          details: [
            "Wordpress Website with ACF implementation",
            "Utilization of Bootstrap 4, Slick Slider and Tock Reservation System"
          ]
        },
      ],
      skills : {
        languages : [
          {name : 'PHP', time : '8 years'},
          {name : 'HTML', time : '8 years'},
          {name : 'CSS', time : '8 years'},
          {name : 'Javascript', time : '6 years'},
          {name : 'Typescript', time : '1 year'},
          {name : 'Liquid', time : '1 year'},
          {name : 'SQL', time : '7 years'}
        ],
        libraries : [
          {name : 'Node.js', time : '3 years'},
          {name : 'Vue.js', time : '3.5 years'},
          {name : 'Express.js', time : '1 year'},
          {name : 'Angular', time : '1 year'},
          {name : 'Laravel', time : '4 years'},
          {name : 'Angular.js', time : '2.5 years'},
          {name : 'Code Igniter', time : '3.5 years'}
        ],
        software : [
          {name : 'Wordpress', time : '8 years'},
          {name : 'Shopify', time : '1.5 years'},
          {name : 'Magento', time : '3 years'},
          {name : 'Git', time : '7 years'}
        ],
        apis : [
          {name : 'Amazon', time : '2 years'},
          {name : 'eBay', time : '2 years'},
          {name : 'PayPal', time : '3 years'},
          {name : 'NetSuite', time : '2.5 years'},
          {name : 'Magento', time : '1 year'},
          {name : 'Acumatica', time : '1 year'}
        ]
      },
      contact : "tomdburtless@gmail.com",
      active: null
    }
  },
  methods : {
    getImgUrl(pic) {
      return require('../assets/'+pic);
    },
    setActive(portfolio_item) {
      this.active = portfolio_item;
    }
  }
}
</script>

<style lang="scss">
  $bg_color : #998A85;

  .container {
    max-width: 900px;
    margin: 0px auto;
  }

  #top_image {
    background-image: url('../assets/tb-3.jpg');
    background-size: cover;
    background-position: left;
    width: 100vw;
    height: 100vh;

    .top-image-copy {
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: flex-end;
      height: 100vh;
      width: 100vw;

      .top-image-copy-inner {
        h1 {
          font-size: 75px;
          font-family: "Josefin Sans";
          color: #fff;
          letter-spacing: 3px;
          text-transform: uppercase;
          font-size: 45px;
          margin-bottom: 10px;
          text-shadow: 6px 4px rgba(0,0,0, 1);
        }

        h5 {
          font-size: 25px;
          font-family: "Josefin Sans";
          color: #ddd;
          letter-spacing: 5px;
          text-transform: lowercase;
          margin-top: 0px;
          text-shadow: 3px 3px rgba(0,0,0, .5);
        }
      }
    }
  }

  h2 {
    background-color: $bg_color;
    text-shadow: 4px 4px darken($bg_color, 20%);
    color: #fff;
    padding: 20px;
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 0px;
    margin-top: 0px;
    position: sticky;
    top: 0px;
    z-index: 1000;

    small {
      display: block;
      font-size: 17px;
      text-shadow: none;
      font-style: italic;
      font-weight: 400;
      line-height: 30px;
    }

    .mobile {
      display: inline-block;
    }
    .desktop {
      display: none;
    }
  }

  #skills {
    min-height: 100vh;
    padding: 100px 0px;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    .skills-inner {

    }

    .skill {
      padding: 25px;
    }

    h3 {
      text-transform: uppercase;
      letter-spacing: 3px;
      border-bottom: 2px solid #333;
      padding-bottom: 5px;
      display: inline-block;
      font-size: 22px;
    }

    .skills {
      span {
        display: block;
        padding: 20px 10px;
        font-size: 22px;

        i {
          font-size: 16px;
          display: block;
          opacity: .5;
        }
      }
    }
  }

  #resume {
    padding: 100px 20px;

    hr {
      border: none;
      border-bottom: 1px solid #333;
    }

    h3 {
      font-size: 35px;
      margin-bottom: 0px;
      margin-top: 0px;
    }

    h4 {
      font-size: 25px;
      margin-bottom: 0px;
      margin-top: 0px;
    }

    p {
      margin: 20px 0px;
      background-color: $bg_color;
      color: #fff;
      opacity: .75;
      padding: 10px;
      width: auto;
      display: inline-block;
      letter-spacing: 5px;
      font-family: "Josefin Sans";
      text-transform: uppercase;
    }

    .job {
      border-bottom: 3px solid $bg_color;
      padding: 60px 0px;

      ul.details {
        margin-bottom: 0px;

        li {
          font-size: 20px;
          line-height: 28px;
          padding: 10px 0px;
        }
      }

      &:first-of-type {
        margin-top: 0px;
        padding-top: 0px;
      }

      &:last-of-type {
        border: none;
        padding-bottom: 0px;
      }
    }
  }

  #portfolio {
    .portfolio-items {
      margin: 100px 0px;

      .portfolio-item {
        padding-bottom: 100px;

        .portfolio-image {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          .portfolio-image-inner {
            border-radius: 10px;
            box-shadow: 0px 5px 10px rgba(0,0,0, .35);
            overflow: hidden;
            width: 85%;
            position: relative;

            img {
              width: 100%;
              margin-bottom: -5px;
            }

            .portfolio-details {
              opacity: 0;
              position: absolute;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%;
              background-color: rgba(0,0,0, .85);
              padding: 15px;
              box-sizing: border-box;
              transition: all .4s;
              display: flex;
              justify-content: center;
              align-items: center;


              h3 {
                text-align: left;
                font-size: 35px;
                color: #fff;
                padding-left: 25px;
                margin-top: 0px;
                margin-bottom: 0px;
              }

              .details {
                color: #fff;

                li {
                  font-size: 20px;
                  line-height: 25px;
                  padding: 10px 0px;
                }
              }

               a {
                 background-color: rgba($bg_color, .8);
                 color: #fff;
                 font-size: 18px;
                 padding: 15px 10px;
                 margin: 25px 25px 0px 25px;
                 display: block;
                 text-decoration: none;
                 text-align: center;
                 font-family: "Josefin Sans";
                 text-transform: uppercase;
                 letter-spacing: 2px;
                 transition: all .4s;

                 &:hover {
                   background-color: rgba($bg_color, 1);
                 }
               }
            }

            &:hover, &.active {
              .portfolio-details {
                opacity: 1;
              }
            }
          }
        }

        &:last-of-type {
          padding-bottom: 0px;
        }

        &.active {
          .portfolio-image-inner {
            .portfolio-details {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  #contact {
    min-height: 100vh;
    padding: 100px 0px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    h3 {
      padding: 0px 30px;
      font-size: 55px;
      line-height: 70px;
      margin-bottom: 50px;
      margin-top: 0px;
    }

    a {
      background-color: rgba($bg_color, .8);
      padding: 30px;
      font-family: "Josefin Sans";
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 25px;
      text-decoration: none;
      color: #fff;
      transition: all .4s;
      display: inline-block;

      &:hover {
        background-color: rgba($bg_color, 1);
      }
    }
  }

  @media (max-width: 991px) {
    #top_image {
      justify-content: center;
      align-items: flex-end;

      #top_image_likes {
        margin-bottom: 20px;

        h3 {
          font-size: 30px;
          text-shadow: 3px 3px darken($bg_color, 20%);
        }

        h1 {
          font-size: 40px;
        }
      }
    }
  }



  @media (min-width: 768px) {
    h2 {
      font-size: 45px;
      line-height: 50px;
      padding: 20px;

      .mobile {
        display: none;
      }
      .desktop {
        display: inline-block;
      }
    }

    #skills {
      .skills-inner {
        align-items: flex-start;
        justify-content: space-between;
        display: flex;
        width: 100%;
      }
    }


    #top_image {
      .top-image-copy {
        text-align: center;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 50vw;
        margin-left: 50vw;
      }
    }

    #portfolio {
      .portfolio-items {
        .portfolio-item {
          .portfolio-image {
            .portfolio-image-inner {
              .portfolio-details {
                padding: 30px;
              }
            }
          }
        }
      }
    }
  }
</style>
